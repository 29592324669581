@import "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap";
html {
  font-size: 10px;
}

body {
  font-family: Roboto, sans-serif;
}

@font-face {
  font-family: YoungerThanMe;
  src: url("YoungerThanMe.0f1d8dc4.woff2") format("woff2"), url("YoungerThanMe.fb6faecf.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: PoppinsRegular;
  src: url("PoppinsRegular.f66ffc6c.woff2") format("woff2"), url("PoppinsRegular.ed0002bb.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: PoppinsBold;
  src: url("PoppinsBold.e9f8ae89.woff2") format("woff2"), url("PoppinsBold.b27fdb88.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: TekoMedium;
  src: url("TekoMedium.9fd07cf0.woff2") format("woff2"), url("TekoMedium.f8dbf7ad.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

:root {
  --primary-color: #658354;
  --complementary-color: #d87f33;
  --accent-color-1: #8fa382;
  --accent-color-2: #4c634b;
  --neutral-light: #f3f4f6;
  --neutral-dark: #403d39;
  --secondary-accent: #a67c52;
  --background-color: var(--neutral-light);
  --text-color: var(--neutral-dark);
  --border-color: #dadada;
}

body.dark-theme {
  --primary-color: #658354;
  --complementary-color: #d87f33;
  --accent-color-1: #8fa382;
  --accent-color-2: #4c634b;
  --neutral-light: #2e2e2e;
  --neutral-dark: #f3f4f6;
  --secondary-accent: #a67c52;
  --background-color: var(--neutral-light);
  --text-color: var(--neutral-dark);
  --border-color: #555;
}
/*# sourceMappingURL=index.ac673c0d.css.map */
