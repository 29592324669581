@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
html {
  font-size: 10px;
}

body {
  font-family: 'Roboto', sans-serif;
}

@font-face {
  font-family: 'YoungerThanMe';
  src:
    url('../public/fonts/YoungerThanMe.woff2') format('woff2'),
    url('../public/fonts/YoungerThanMe.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PoppinsRegular';
  src:
    url('../public/fonts/PoppinsRegular.woff2') format('woff2'),
    url('../public/fonts/PoppinsRegular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PoppinsBold';
  src:
    url('../public/fonts/PoppinsBold.woff2') format('woff2'),
    url('../public/fonts/PoppinsBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TekoMedium';
  src:
    url('../public/fonts/TekoMedium.woff2') format('woff2'),
    url('../public/fonts/TekoMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

:root {
  --primary-color: #658354; /* Primary color */
  --complementary-color: #d87f33; /* Complementary color */
  --accent-color-1: #8fa382; /* Lighter shade of primary */
  --accent-color-2: #4c634b; /* Darker shade of primary */
  --neutral-light: #f3f4f6; /* Light neutral for backgrounds */
  --neutral-dark: #403d39; /* Dark neutral for text */
  --secondary-accent: #a67c52; /* Secondary accent color */

  /* Specific for light theme */
  --background-color: var(--neutral-light); /* Main background */
  --text-color: var(--neutral-dark); /* Main text color */
  --border-color: #dadada; /* Light border for elements */
}

body.dark-theme {
  --primary-color: #658354; /* Primary color */
  --complementary-color: #d87f33; /* Complementary color */
  --accent-color-1: #8fa382; /* Lighter shade of primary */
  --accent-color-2: #4c634b; /* Darker shade of primary */
  --neutral-light: #2e2e2e; /* Dark neutral for backgrounds */
  --neutral-dark: #f3f4f6; /* Light neutral for text */
  --secondary-accent: #a67c52; /* Secondary accent color */

  /* Specific for dark theme */
  --background-color: var(--neutral-light); /* Main background */
  --text-color: var(--neutral-dark); /* Main text color */
  --border-color: #555555; /* Dark border for elements */
}
